* {
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    color: #444444;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: fixed;
    top: 0;
    width: 100%;
    height: 54px;
    overflow: hidden;
    background-color: #000000da;
    align-items: center;
    /* color: white; */
    z-index: 1;
}

a {
    font-family: system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,Helvetica Neue;
    font-weight: 700;
}

.logoname {
    display: flex;
    font-size: 24pt;
    /* font-weight: lighter; */
    color: white;
    cursor: default;
}

.navigation {
    display: flex;
    cursor: pointer;
    transition-duration: 200ms;
    color: white;
    
}
.navigation-active{
    display: flex;
    cursor: pointer;
    color: blue;
    font-size: 16pt;
    transition-duration: 200ms;
}

/*  */

/*          Footer           */

/*  */

footer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: solid 1px grey;
    gap: 30px;
}

.footer-img {
    display: flex;
    height: 70px;
    width: 70px;
    border-radius: 100%;
}